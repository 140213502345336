<template>
  <div class="login-page">
    <van-image :src="require('@/assets/lnr.png')" class="background"></van-image>
    <van-field  size="large" v-model="phone" type="tel" label="手机号" placeholder="请输入手机号" ></van-field>
    <van-button @click="login" type="info" size="normal">去登录</van-button>
  </div>
</template>
<script>
export default {
  name: "DrivelLogin",
  data() {
    return {
      phone: "",
    };
  },
  created() {
    let phone = localStorage.getItem('phone');
    if (phone) {
      this.phone = phone;
    }
  },
  methods: {
    login() {
      if (!this.phone || this.phone.length === 0) {
        this.$toast.fail({
          message: '请输入手机号',
          duration: 1000
        })
      } else {
        localStorage.setItem('phone', this.phone);
        this.$router.push({
          path: '/driveList'
        })
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.login-page {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

button {
  margin-top: 20px;
  width: 250px;
}
</style>
